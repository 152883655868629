<template>
  <v-dialog
      v-model="dialog"
      max-width="800px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
      >Aggiungi Ordine
      </v-btn>
    </template>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding card-border-bottom">
        <span class="font-weight-bold text-h5 text-typo mb-0">Aggiungi Nuovo Ordine</span>
      </div>
      <v-card
          class="pl-5 pr-5 mt-5 ml-5 mr-5"
          outlined
          style="background-color: #ebebeb"
      >
        <v-row>
          <!-- Autocomplete per Ricerca Produttore -->
          <v-col>
            <v-autocomplete
                v-model="ricerca_produttore"
                :search-input.sync="search_produttore"
                :items="risultatiProduttori"
                item-value="id"
                :item-text="getWinemakerName"
                return-object
                label="Ricerca per Produttore"
                clearable
                no-data-text="Nessun produttore trovato"
                :filter="() => true"
                @update:model-value="updateCustomId"
            >
              <template v-slot:append>
                <v-progress-circular
                    v-if="loadingProduttore"
                    indeterminate
                    size="20"
                    color="primary"
                ></v-progress-circular>
              </template>
            </v-autocomplete>
          </v-col>

          <!-- Autocomplete per Ricerca Distributore -->
          <v-col>
            <v-autocomplete
                v-model="ricerca_distributor"
                :search-input.sync="search_distributor"
                :items="risultatiDistributori"
                item-value="id"
                :item-text="getDistributorName"
                return-object
                label="Ricerca per Distributore"
                clearable
                no-data-text="Nessun distributore trovato"
                :filter="() => true"
                @update:model-value="updateCustomId"
            >
              <template v-slot:append>
                <v-progress-circular
                    v-if="loadingDistributor"
                    indeterminate
                    size="20"
                    color="primary"
                ></v-progress-circular>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card>
      <!-- Dati del produttore o distributore selezionato -->
      <v-card class="mt-4">
        <v-card-title>
          Ordine a:
          <span class="ml-2 text-pink" v-if="ricerca_produttore && ricerca_produttore.winemaker_name">
            {{ ricerca_produttore.winemaker_name }}
          </span>
          <span class="ml-2 text-indigo" v-else-if="ricerca_distributor && ricerca_distributor.distributor.name">
            {{ ricerca_distributor.distributor.name }}
          </span>
        </v-card-title>
      </v-card>

      <!-- Custom ID Input -->
      <v-card-text class="card-padding">
        <v-container class="px-0">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="composeNewOrder.customId" label="Titolo Ordine Univoco*"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="card-padding d-flex justify-end">
        <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
            @click="dialog = false"
        >Cancella
        </v-btn>
        <v-btn
            @click="save"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
        >Salva
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OrdineService from "@/services/ordine.service";
import listinoService from "@/services/listino.service";

export default {
  data() {
    return {
      dialog: false,
      ricerca_produttore: null,
      ricerca_distributor: null,
      search_produttore: "",
      search_distributor: "",
      loadingProduttore: false,
      loadingDistributor: false,
      risultatiProduttori: [],
      risultatiDistributori: [],
      composeNewOrder: {
        customId: "",
      },
    };
  },
  methods: {
    async save() {
      if (!this.composeNewOrder.customId) {
        await this.$dialog.notify.error("Custom Id Obbligatorio", {color: "red"});
        return;
      }

      const payload = {
        custom_id: this.composeNewOrder.customId,
        winemaker: this.ricerca_produttore?.winemaker || null,
        distributor: this.ricerca_distributor?.distributor.id || null,
        store: this.$store.state.auth.user.store_id,
        status: "In Progress",
      };

      try {
        await OrdineService.newOrdine(payload);
        this.$emit('refreshOrder')
        await this.$dialog.message.success("Ordine aggiunto correttamente", {
          rounded: true,
          position: "top-right",
          color: "green",
          timeout: 1000,
        });
        this.resetForm();
      } catch (error) {
        await this.$dialog.message.error("Errore durante il salvataggio dell'ordine", {
          rounded: true,
          position: "top-right",
          color: "red",
          timeout: 1000,
        });
      }
    },

    resetForm() {
      this.ricerca_produttore = null;
      this.ricerca_distributor = null;
      this.composeNewOrder = {customId: ""};
      this.dialog = false;
    },

    async fetchProduttori() {
      if (this.search_produttore.length < 3) return;

      this.loadingProduttore = true;
      try {
        const params = {
          store_id: this.$store.state.auth.user.store_id,
          winemaker_name: this.search_produttore,
        };
        const response = await listinoService.getListiniItems(params);
        this.risultatiProduttori = response.data.results;
      } catch (error) {
        console.error("Errore nel caricamento dei produttori", error);
      } finally {
        this.loadingProduttore = false;
      }
    },

    async fetchDistributori() {
      if (this.search_distributor.length < 3) return;

      this.loadingDistributor = true;
      try {
        const params = {
          store_id: this.$store.state.auth.user.store_id,
          distributor_name: this.search_distributor,
        };
        const response = await listinoService.getListiniItems(params);
        this.risultatiDistributori = response.data.results;
      } catch (error) {
        console.error("Errore nel caricamento dei distributori", error);
      } finally {
        this.loadingDistributor = false;
      }
    },

    updateCustomId() {
      if (this.ricerca_produttore) {
        this.composeNewOrder.customId = `Ordine Produttore: ${this.ricerca_produttore.name}`;
      } else if (this.ricerca_distributor) {
        this.composeNewOrder.customId = `Ordine Distributore: ${this.ricerca_distributor.name}`;
      } else {
        this.composeNewOrder.customId = "";
      }
    },

    getWinemakerName(item) {
      return item?.winemaker_name || "Nome non disponibile";
    },
    getDistributorName(item) {
      return item?.distributor.name || "Nome non disponibile";
    },
  },
  watch: {
    search_produttore: "fetchProduttori",
    search_distributor: "fetchDistributori",
  },
};
</script>

<style scoped>
</style>
